/*
 * Spacing values
 * Spacing values are static and don’t change based on platform scale. Different spacing values can be used for different sizes and scales as needed.
 * See: https://spectrum.adobe.com/page/spacing/
 */
$spacing: (
  50: 2px,
  75: 4px,
  100: 8px,
  200: 12px,
  300: 16px,
  400: 24px,
  500: 32px,
  600: 40px,
  700: 48px,
  800: 64px,
  900: 80px,
  1000: 96px,
) !default;
