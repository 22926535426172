/**
 * GRAY PALLETE
 */

$gray-palette: (
  0: #fff,
  50: #f7f7f7,
  100: #fcfcfd,
  200: #eaecf0,
  300: #f0f0f0,
  400: #dee5ed,
  600: #ced4da,
  700: #b1b8c0,
  800: #adb5bd,
  900: #94a3b8,
  950: #90a29b,
  1000: #868e96,
  1100: #637280,
  1200: #545454,
  1300: #5c6775,
  1400: #6b6b6b,
  // same as: #6e6e6e
  1500: #b7b7b8,
);

/**
 * BLUE PALLETE
 */

$blue-palette: (
  50: #f1f5f9,
  100: #edf8ff,
  200: #cce5ff,
  250: #b0d6ff,
  300: #99e9f2,
  350: #36e5f9,
  375: #32aed4,
  400: #0f96f0,
  450: #4093f3,
  500: #0e90e4,
  600: #0d7bc5,
  700: #0b79ef,
  750: #405df3,
  800: #0766cd,
  850: #0656ac,
  875: #15489b,
  900: #004085,
  1000: #32464c,
  1150: #2c3749,
  1100: #1e293b,
  1200: #0f172a,
  1300: #06090d,
);

/**
 * RED PALLETE
 */

$red-palette: (
  100: #f8d7da,
  200: #ffc9c9,
  300: #fcc2d7,
  350: #f1c0c6,
  400: #d29b9b,
  425: #d29bb5,
  450: #e06666,
  500: #f44336,
  600: #f34056,
  700: #f55c6e,
  750: #ff0927,
  800: #da2020,
  900: #d01027,
  950: #ab0d1f,
  1000: #721c24,
);

/**
 * GREEN PALLETE
 */

$green-palette: (
  50: #d1e7dd,
  300: #b2f2bb,
  400: #69db7c,
  500: #00a83b,
  525: #189a71,
  550: #009963,
  575: #168c67,
  600: #1c78314d,
  700: #026e00,
  750: #1a702e,
);

/**
 * SEAFOAM PALLETE
 */

$seafoam-palette: (
  75: #bdedde,
  100: #96f2d7,
  200: #42eab8,
  500: #009999,
  800: #3a7f7c,
  900: #469b80,
);

/**
 * YELLOW PALLETE
 */

$yellow-palette: (
  100: #fff9e5,
  150: #ffefb8,
  200: #ffd3a1,
  500: #f0bc01,
  800: #b89233,
  900: #d3840d,
  // partial ssn
);

/**
 * ORANGE PALLETE
 */

$orange-palette: (
  100: #e08b66,
  800: #f37640,
  850: #d46332,
  900: #9e5400,
);

/**
 * CHARTREUSE PALLETE
 */

$chartreuse-palette: (
  500: #b8b105,
  600: #bba900,
  800: #737c13,
);

/**
 * PURPLE PALLETE
 */

$purple-palette: (
  500: #a440f3,
);
