/*
 * Font sizes
 * Spectrum ensures that different sizes of text can work together harmoniously, on both desktop and mobile. All font sizes have a ratio of 1.125, known as the major second type scale. This means that each size is multiplied or divided by 1.125 from the previous size, starting with the base size, and rounded. Custom text (any non-existing typography component) should use a font size from this list.
 * See: https://spectrum.adobe.com/page/typography/
 */
$font-size: (
  50: 11px,
  75: 12px,
  100: 14px,
  150: 15px,
  200: 16px,
  300: 18px,
  400: 20px,
  500: 22px,
  600: 25px,
  700: 28px,
  800: 32px,
  900: 36px,
  1000: 40px,
  1100: 45px,
  1200: 50px,
  1300: 60px,
) !default;

$font-base: map-get($font-size, 200);
