@import 'core/settings/settings';
@import 'core/tools/tools';

/**
 * Theme
 */
@include themify('sidenav') using ($component) {
  .sidenav {
    .menu {
      background-color: map-get($component, 'background');
      color: map-get($component, 'text');

      &__item {
        background-color: map-get($component, 'background');
        a, div {
          color: map-get($component, 'text');
        }

        &:hover {
          background-color: map-get($component, 'hover', 'background');
          a, div, a.active {
            color: map-get($component, 'hover', 'text');
          }
        }

        a.active {
          color: map-get($component, 'active', 'text');
        }
      }
    }
  }
}

/**
 * General styles
 */
.sidenav {
  .menu {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, 75);

    &:not(.menu--expanded) .menu__item span {
      width: 0;
      text-indent: -30rem;
    }

    &__item {
      min-height: 32px;
      height: 32px;
      border-radius: map-get($round-corners, 'small');

      a,
      div {
        display: flex;
        align-items: center;
        gap: map-get($spacing, 200);
        padding: 0 map-get($spacing, 300);

        cursor: pointer;
        text-decoration: none;
        width: 100%;

        font-family: 'Nunito Sans', sans-serif;
        font-size: $font-base;
        line-height: 20px;

        fo-icon {
          width: 24px;
          height: 24px;
        }
      }

      &--expandible {
        div {
          fo-icon:last-child {
            margin-left: auto;
          }
        }
      }

      span {
        text-indent: 0;
        overflow: hidden;
        transition: map-get($transition, 'cubic-bezier');
        transition-property: text-indent, width;
        white-space: nowrap;
      }
    }

    .mat-tree-node[aria-level='2'] {
      padding-left: map-get($spacing, 200) !important;
    }
  }
}
