/* 
 * Z-index 
 */

$z-layers: (
  'back': -1,
  'none': 0,
  'floor': 1,
  'semi-small': 4,
  'small': 5,
  'medium': 10,
  'up': 50,
  'top': 100,
  'max': 1000,
) !default;
