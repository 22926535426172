@use 'core/settings/palette' as palette;
@use '@angular/material' as mat;

/*
 * Define a light theme
 */
$light-primary: mat.define-palette(palette.$primary, 500);
$light-accent: mat.define-palette(palette.$primary, 500);
$light-warn: mat.define-palette(palette.$warn, 500);

$mat-light-components: (
  foreground: (
    base: $text1,
    divider: rgba(0, 0, 0, 0.12),
    dividers: rgba(0, 0, 0, 0.12),
    disabled: rgba(0, 0, 0, 0.38),
    disabled-button: map-get($gray-palette, 0),
    disabled-text: rgba(0, 0, 0, 0.38),
    elevation: black,
    hint-text: rgba(0, 0, 0, 0.38),
    secondary-text: rgba(0, 0, 0, 0.54),
    icon: $text1,
    icons: $text1,
    text: $text1,
    slider-min: $text1,
    slider-off: rgba(0, 0, 0, 0.26),
    slider-off-active: rgba(0, 0, 0, 0.38),
  ),
  background: (
    status-bar: #e0e0e0,
    app-bar: #f5f5f5,
    background: #fafafa,
    hover: rgba(0, 0, 0, 0.04),
    card: $background2,
    dialog: $background2,
    disabled-button: map-get($gray-palette, 1400),
    raised-button: $background2,
    focused-button: rgba(0, 0, 0, 0.12),
    selected-button: #e0e0e0,
    selected-disabled-button: #bdbdbd,
    disabled-button-toggle: #eeeeee,
    unselected-chip: #e0e0e0,
    disabled-list-option: #eeeeee,
    tooltip: #616161,
  ),
);

$material-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $light-primary,
      accent: $light-accent,
      warn: $light-warn,
    ),
  )
);

// Add to main properties
$material-light-theme: map-merge($material-light-theme, $mat-light-components);

// Add to color property
$color: map-get($material-light-theme, color);
$color: map-merge($color, $mat-light-components);
$material-light-theme: map-merge(
  $material-light-theme,
  (
    color: $color,
  )
);
