/**
 * Aliases
 */
$fundoBlue: map-get($blue-palette, 400);
$fundoBlueHover: map-get($blue-palette, 600);
$fundoBlueActive: map-get($blue-palette, 800);
$background1: map-get($blue-palette, 1200);
$background2: map-get($blue-palette, 1100);
$text1: map-get($gray-palette, 900);
$text2: map-get($blue-palette, 1100);
$fundoRed1: map-get($red-palette, 600);
$fundoRed2: map-get($red-palette, 700);
$fundoDisabled1: map-get($gray-palette, 1500);

$background1hover: lighten($background1, 15%);
$background2hover: lighten($background2, 15%);
$text1hover: lighten($text1, 15%);

/**
  * Theme
  */
$dark-theme: (
  'layout': (
    'background': $background1,
    'title': $fundoBlue,
    'text': $text1,
    'error': $fundoRed1,
  ),
  'login-page': (
    'background': $background1,
    'card': (
      'border': none,
      'shadow': map-get($shadow, '7'),
    ),
  ),
  'header': (
    'background': $background2,
    'text': $text1,
    'shadow': map-get($shadow, '4'),
    'background-hover': $background1hover,
    'text-hover': $text1hover,
    'avatar': (
      'background': map-get($gray-palette, 300),
      'text': map-get($blue-palette, 1100),
    ),
    'slide-toggle': (
      'border': $fundoBlue,
      'text': $fundoBlue,
      'option-selected': (
        'background': $fundoBlue,
        'text': map-get($blue-palette, 1100),
      ),
    ),
  ),
  'sidenav': (
    'background': $background2,
    'text': $text1,
    'shadow': map-get($shadow, '4'),
    'hover': (
      'background': $text1,
      'text': $text2,
    ),
    'active': (
      'text': $fundoBlue,
    ),
  ),
  'breadcrumb': (
    'background': $background1,
    'text': $text1,
    'text-active': $fundoBlue,
  ),
  'a': (
    'text': $fundoBlue,
    'text-hover': $fundoBlueHover,
    'text-visited': $fundoBlueActive,
  ),
  'table-content-container': (
    'background': $background2,
    'shadow': map-get($shadow, 'none'),
  ),
  'table': (
    'border': none,
    'header': (
      'background': $background2,
      'text': $text1,
      'border': map-get($gray-palette, 200),
    ),
    'body': (
      'background': $background2,
      'text': $text1,
      'border-color': $text1,
      'row': (
        'hover': (
          'background': $text1,
          'text': $text2,
          'border': 1px solid $fundoBlue,
        ),
        'selected': (
          'background': $text1,
          'border': none,
        ),
        'group': (
          'background': map-get($blue-palette, 1150),
          'border': 1px solid $text1,
        ),
        'group-item': (
          'background': rgba(map-get($blue-palette, 1150), 0.5),
          'border': 1px solid $text1,
        ),
      ),
    ),
    'footer': (
      'background': map-get($gray-palette, 1300),
      'text': map-get($blue-palette, 1100),
      'border': 1px solid $text1,
      'row': (
        'hover': (
          'background': $text1,
          'text': $text2,
        ),
      ),
    ),
    'variants': (
      'light-gray': (
        'background': map-get($blue-palette, 1100),
      ),
      'gray': (
        'background': $text1,
        'text': map-get($blue-palette, 1100),
      ),
      'red': (
        'background': map-get($red-palette, 700),
        'text': map-get($blue-palette, 1100),
      ),
      'dark-green': (
        'background': map-get($green-palette, 500),
        'text': map-get($blue-palette, 1100),
      ),
      'light-green': (
        'background': map-get($blue-palette, 450),
        'text': map-get($blue-palette, 1100),
      ),
      'orange': (
        'background': map-get($orange-palette, 800),
        'text': map-get($blue-palette, 1100),
      ),
      'light-red': (
        'background': map-get($red-palette, 400),
        'text': map-get($blue-palette, 1100),
      ),
      'light-blue': (
        'background': map-get($blue-palette, 350),
        'text': map-get($blue-palette, 1100),
      ),
      'green-400': (
        'background': map-get($seafoam-palette, 900),
        'text': map-get($blue-palette, 1100),
      ),
      'green-500': (
        'background': map-get($green-palette, 500),
        'text': map-get($blue-palette, 1100),
      ),
    ),
  ),
  'paginator': (
    'background': $background2,
    'text': $text1,
    'text-hover': $text1hover,
    'text-disabled': rgba($text1, 0.3),
  ),
  'tab': (
    'header': (
      'background': $background2,
      'shadow': map-get($shadow, 'none'),
      'label': (
        'background': $background2,
        'text': $text1,
        'border': 1px solid $text1,
        'text-hover': none,
        'background-hover': none,
        'active': (
          'background': rgba(map-get($gray-palette, 0), 0.3),
          'shadow': map-get($shadow, 'none'),
          'text': $background2,
          'ink-bar': $fundoBlue,
        ),
      ),
    ),
    'body': (
      'variants': (
        'use-background': (
          'background': $background2,
        ),
      ),
    ),
  ),
  'button': (
    'raised': (
      'disabled': (
        'default': (
          'background': map-get($gray-palette, 1500),
          'text': map-get($gray-palette, 0),
        ),
        'hover': (
          'background': map-get($gray-palette, 1500),
          'text': map-get($gray-palette, 0),
        ),
      ),
      'primary': (
        'default': (
          'background': $fundoBlue,
          'text': $text2,
          'shadow': map-get($shadow, '1'),
        ),
        'hover': (
          'background': $fundoBlueHover,
          'text': map-get($gray-palette, 0),
        ),
        'clicked': (
          'background': map-get($blue-palette, 800),
          'text': map-get($gray-palette, 0),
        ),
      ),
    ),
    'stroked': (
      'disabled': (
        'default': (
          'background': transparent,
          'border': 1px solid map-get($gray-palette, 1500),
          'text': map-get($gray-palette, 1500),
        ),
        'hover': (
          'background': transparent,
          'border': 1px solid map-get($gray-palette, 1500),
          'text': map-get($gray-palette, 1500),
        ),
      ),
      'primary': (
        'default': (
          'background': transparent,
          'border': 1px solid $fundoBlue,
          'text': $fundoBlue,
          'shadow': map-get($shadow, '1'),
        ),
        'hover': (
          'background': transparent,
          'border': 1px solid $fundoBlueHover,
          'text': $fundoBlueHover,
        ),
        'clicked': (
          'background': transparent,
          'border': 1px solid map-get($blue-palette, 800),
          'text': map-get($blue-palette, 800),
        ),
      ),
    ),
  ),
  'card': (
    'background': $background2,
    'text': $text1,
    'shadow': map-get($shadow, '5'),
  ),
  'dialog': (
    'border': 1px solid map-get($gray-palette, 700),
    'title': $fundoBlue,
    'button-close': (
      'color': $text1,
    ),
  ),
  'select': (
    'text': $text1,
    'panel': (
      'shadow': map-get($shadow, '5'),
    ),
    'option': (
      'background': $background1,
      'text': $text1,
      'active': (
        'background': $text1,
        'text': $text2,
      ),
      'hover': (
        'background': $text1,
        'text': $text2,
        'border': none,
      ),
    ),
  ),
  'chips': (
    'background': transparent,
    'border': 1px solid $fundoBlue,
    'text': $text1,
    'active': (
      'background': $fundoBlue,
      'border': 1px solid $fundoBlue,
      'text': $background2,
    ),
    'error': (
      'background': transparent,
      'border': 1px solid $fundoRed1,
      'text': $fundoRed1,
    ),
  ),
  'tooltip': (
    'background': $background2,
    'shadow': map-get($shadow, '6'),
    'text': $text1,
    'list': (
      'item': (
        'hover': (
          'background': $text1,
          'text': $text2,
        ),
      ),
    ),
  ),
  'badge': (
    'variants': (
      'green': (
        'background': map-get($green-palette, 500),
        'text': map-get($gray-palette, 0),
      ),
      'red': (
        'background': $fundoRed1,
        'text': map-get($gray-palette, 0),
      ),
      'yellow': (
        'background': map-get($chartreuse-palette, 500),
        'text': map-get($gray-palette, 0),
      ),
      'green-2': (
        'background': map-get($green-palette, 525),
        'text': $background1,
      ),
      'green-3': (
        'background': map-get($green-palette, 500),
        'text': map-get($gray-palette, 0),
      ),
      'red-2': (
        'background': $fundoRed2,
        'text': $background1,
      ),
      'yellow-2': (
        'background': map-get($yellow-palette, 500),
        'text': $background1,
      ),
      'gray': (
        'background': map-get($blue-palette, 1200),
        'text': map-get($gray-palette, $key: 900),
      ),
      'active': (
        'background': map-get($green-palette, 500),
        'text': map-get($blue-palette, 1100),
      ),
      'inactive': (
        'background': map-get($gray-palette, 700),
        'text': map-get($blue-palette, 1100),
      ),
      'info': (
        'background': map-get($green-palette, 500),
        'text': map-get($blue-palette, 1100),
      ),
      'ramp-100': (
        'background': #f55c6e,
        'text': map-get($gray-palette, 0),
      ),
      'ramp-200': (
        'background': #ff570f,
        'text': map-get($gray-palette, 0),
      ),
      'ramp-300': (
        'background': #ba5326,
        'text': map-get($gray-palette, 0),
      ),
      'ramp-400': (
        'background': #e07800,
        'text': map-get($gray-palette, 0),
      ),
      'ramp-500': (
        'background': #fa8500,
        'text': map-get($gray-palette, 0),
      ),
      'ramp-600': (
        'background': #00a83b,
        'text': map-get($gray-palette, 0),
      ),
    ),
  ),
  'state-toggle': (
    'background': (
      'on': map-get($green-palette, 500),
      'off': $text1,
    ),
    'text': map-get($blue-palette, 1100),
    'font-weight': 450,
    'before': (
      'background': map-get($blue-palette, 1100),
    ),
  ),
  'radio-button': (
    'outer-circle': $text1,
    'primary': $fundoBlue,
    'disabled': $fundoDisabled1,
  ),
  'checkbox': (
    'primary': $fundoBlue,
    'disabled': (
      'text': $text1,
      'frame': $fundoDisabled1,
    ),
  ),
  'datepicker': (
    'background': (
      'hover': rgba($fundoBlue, 0.2),
      'active': $fundoBlue,
    ),
  ),
  'selection-list': (
    'text': $text1,
  ),
  'apexcharts': (
    'text': $text1,
  ),
  'loader': (
    'primary': $fundoBlue,
    'background': rgba($background2, 0.8),
  ),
  'alert': (
    'variants': (
      'primary': (
        'background': map-get($blue-palette, 250),
        'text': map-get($blue-palette, 850),
        'shadow': map-get($shadow, '8'),
      ),
      'secondary': (
        'background': map-get($blue-palette, 100),
        'text': $fundoBlue,
        'shadow': map-get($shadow, '8'),
      ),
      'success': (
        'background': map-get($seafoam-palette, 75),
        'text': map-get($green-palette, 750),
        'shadow': map-get($shadow, '8'),
      ),
      'danger': (
        'background': map-get($red-palette, 350),
        'text': map-get($red-palette, 950),
        'shadow': map-get($shadow, '8'),
      ),
      'info': (
        'background': transparent,
        'text': $text1,
        'shadow': map-get($shadow, '8'),
        'border': 1px solid $text1,
      ),
      'warning': (
        'background': map-get($yellow-palette, 150),
        'text': map-get($orange-palette, 900),
        'shadow': map-get($shadow, '8'),
      ),
    ),
  ),
  'form-field': (
    'label': $text1,
    'border-color': $text1,
    'suffix': (
      'active': $fundoBlue,
    ),
    'states': (
      'focused': (
        'label': $text1,
        'border-color': $fundoBlue,
      ),
      'disabled': (
        'label': $text1,
        'border-color': $text1,
        'background': rgba(255, 255, 255, 0.2),
      ),
    ),
  ),
  'form-field-hovered-row': (
    'label': $text2,
    'border-color': $text2,
    'suffix': (
      'active': $fundoBlue,
    ),
    'states': (
      'focused': (
        'label': $text2,
        'border-color': $fundoBlue,
      ),
      'disabled': (
        'label': $text2,
        'border-color': $text2,
        'background': rgba(255, 255, 255, 0.2),
      ),
    ),
  ),
  'select-input': (
    'background': transparent,
  ),
  'range-input': (
    'background': rgba($fundoBlue, 0.3),
    'range': $fundoBlue,
    'thumb': $fundoBlue,
    'invalid': (
      'background': rgba($fundoRed1, 0.3),
      'range': $fundoRed1,
      'thumb': $fundoRed1,
    ),
  ),
  'score-graph': (
    'background': $background2,
    'line': map-get($blue-palette, 200),
    'text': (
      'light-green': map-get($green-palette, 400),
      'red': map-get($red-palette, 700),
    ),
    'pointer': map-get($blue-palette, 200),
    'variants': (
      'red': (
        'background': map-get($red-palette, 700),
      ),
      'light-green': (
        'background': map-get($green-palette, 400),
      ),
    ),
  ),
  'expansion-panel': (
    'border': 1px solid $text1,
    'shadow': map-get($shadow, '7'),
  ),
  'manual-automated-flag': (
    'border': solid 1px $fundoBlue,
  ),
  'image-zoom': (
    'card': (
      'border': map-get($gray-palette, 200)
    ),
    'icon': (
      'color': map-get($gray-palette, 200)
    )
  ),
);
