@mixin mat-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

@mixin themify($component, $themes: $themes) {
  @each $name, $theme in $themes {
    .#{$name} {
      @content (map-get($theme, $component));
    }
  }
}

/**
 * Mixin for creating media queries based on the breakpoins defined
 */
 @mixin set-breakpoint($breakpoint, $limit: min, $adjust: 0) {
  @if map-has-key($breakpoints, $breakpoint) {
    $value: map_get($breakpoints, $breakpoint) + $adjust;
    // Base styles on xxx-small size
    // This styles will apply in the range from 0 to xx-small
    @if $breakpoint == 'xxx-small' {
      @content;
    } @else {
      @media screen and (#{$limit}-width: $value) {
        @content;
      }
    }
  } @else {
    @warn 'Breakpoint #{$value} not found';
  }
}
