@import 'core/settings/settings';
@import 'core/tools/tools';

/**
 * Theme
 */
@include themify('sidenav') using ($component) {
  .sidenav {
    background-color: map-get($component, 'background');
    color: map-get($component, 'text');
    box-shadow: map-get($component, 'shadow');

    .sidenav__button {
      &:hover {
        background-color: map-get($component, 'hover', 'background');
        color: map-get($component, 'hover', 'text');
      }
    }
  }
}

@include themify('layout') using ($component) {
  .page-content {
    background-color: map-get($component, 'background');
  }
}

/**
 * General styles
 */
.sidenav-container {
  height: 100vh;
}

.mat-drawer-container {
  position: inherit!important;
}

.sidenav {
  margin-top: $header-height;
  transition: width map-get($transition, 'cubic-bezier');

  button.mat-icon-button.sidenav__button {
    display: block;
    margin: map-get($spacing, 100) map-get($spacing, 300) map-get($spacing, 100) auto;
  }
}


.page-content {
  $page-padding: map-get($spacing, 500);

  min-height: 100%;
  padding: 0 $page-padding map-get($spacing, 300) $page-padding;
  transition: margin-left map-get($transition, 'cubic-bezier');
  position: relative;

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: map-get($spacing, 300);
    height: fit-content;

    position: absolute;
    top: 85px;
    right: $page-padding;

    z-index: map-get($z-layers, 'small');

    &.page-content__buttons--sticky {
      position: fixed;
      right: map-get($spacing, 700);
    }
  }
}
