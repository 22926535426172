@import 'core/settings/settings';
@import 'core/tools/tools';

/**
 * Theme
 */
@include themify('header') using ($component) {
  .custom-checkbox {
    input {
      // Unchecked State
      + label {
        > .status-switch {
          border: solid 1px map-get($component, 'slide-toggle', 'border');
          // First option
          &:after {
            color: map-get($component, 'slide-toggle', 'text');
          }
          // Second option
          &:before {
            background-color: map-get($component, 'slide-toggle', 'option-selected', 'background');
            color: map-get($component, 'slide-toggle', 'option-selected', 'text');
          }
        }
      }
      // Checked state
      &:checked + label > .status-switch {
        // First option
        &:before {
          background-color: map-get($component, 'slide-toggle', 'option-selected', 'background');
          color: map-get($component, 'slide-toggle', 'option-selected', 'text');
        }
        // Second option
        &:after { 
          color: map-get($component, 'slide-toggle', 'text');
        }
      }
    }
  }
}

/**
* General styles
*/
.custom-checkbox {
  width: 152px;
  height: 32px;
  input {
    display: none;
    // Unchecked State
    + label {
      height: 100%;
      width: 100%;
      > .status-switch {
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: relative;
        transition: all 0.5s ease;
        border-radius: map-get($round-corners, 'rounded');
        &:before,
        &:after {
          height: 100%;
          width: 50%;
          display: flex;
          align-items: center;
          position: absolute;
          justify-content: center;
          border-radius: map-get($round-corners, 'rounded');
          transition: all 0.3s ease;
        }
        &:before {
          z-index: map-get($z-layers, 'medium');
          content: attr(data-unchecked);
        }
        &:after {
          right: 0;
          content: attr(data-checked);
        }
      }
    }
    // Checked state
    &:checked + label > .status-switch {
      &:before {
        left: 50%;
      }
      &:after {
        left: 0;
      }
    }
  }
}
