/**
 * General definitions
 */
@import 'breakpoints';
@import 'colors';
@import 'font-size';
@import 'round-corners';
@import 'shadow';
@import 'spacing';
@import 'transition';
@import 'z-index';

/**
 * Color themes
 */
@import 'themes';

$header-height: 56px;
