/*
 * Shadow values for elements
 * 
 */

$shadow: (
  'none': none,
  '1': 1px 2px 3px 0px rgba(map-get($blue-palette, 1100), 0.1),
  '2': 0px 1px 3px 0px rgba(map-get($blue-palette, 1100), 0.1),
  '3': 2px 0px 6px 0px rgba(map-get($blue-palette, 1100), 0.1),
  '4': 1px 2px 5px 0px rgba(map-get($blue-palette, 1300), 0.2),
  '5': 0px 4px 6px 0px rgba(map-get($blue-palette, 1300), 0.06),
  '6': 0px 1px 10px 0px rgba(map-get($gray-palette, 0), 0.15),
  '7': 1px 4px 8px 0px rgba(0, 0, 0, 0.05),
  '8': 1px 2px 3px 0px rgba(255, 255, 255, 0.20)
) !default;
