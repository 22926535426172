@import 'core/settings/settings';
@import 'core/tools/tools';

/**
 * Theme
 */
@include themify('header') using ($component) {
  .header {
    background-color: map-get($component, 'background');
    color: map-get($component, 'text');
    box-shadow: map-get($component, 'shadow');

    &__avatar {
      background-color: map-get($component, 'avatar', 'background');
      color: map-get($component, 'avatar', 'text');
    }
  }
}

/**
 * General styles
 */
.header {
  display: flex;
  justify-content: space-between;
  padding: map-get($spacing, 200) map-get($spacing, 400);
  box-sizing: border-box;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  z-index: map-get($z-layers, 'medium');
  max-height: $header-height;

  img {
    max-height: 32px;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: map-get($spacing, 300);
  }

  &__avatar {
    $size: 32px;
    width: $size;
    height: $size;
    font-size: 14px;
    margin-left: map-get($spacing, 100);
    border-radius: map-get($round-corners, 'rounded');
    position: relative;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__singout {
    display: flex;
    align-items: center;
    gap: map-get($spacing, 100);

    &:hover {
      cursor: pointer;
    }

    fo-icon {
      width: 24px;
      height: 24px;
    }
  }
}
