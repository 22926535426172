/*
 * Material design palettes generated in http://mcg.mbitson.com/
 */

$primary: (
  50: #e2eff8,
  100: #b6d7ee,
  200: #86bde2,
  300: #56a3d6,
  400: #0f96f0,
  // dark blue
  500: #0d7bc5,
  600: #0b73bf,
  700: #0968b8,
  800: #075eb0,
  900: #034ba3,
  A100: #cee1ff,
  A200: #9bc2ff,
  A400: #68a2ff,
  A700: #4e93ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #1e293b,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$warn: (
  50: #f9e2e5,
  100: #f1b7be,
  200: #e88893,
  300: #de5868,
  400: #d73447,
  // red
  500: #d01027,
  600: #f55c6e,
  700: #c40c1d,
  800: #be0917,
  900: #b3050e,
  A100: #ffddde,
  A200: #ffaaac,
  A400: #ff777a,
  A700: #ff5e61,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
