@import 'core/settings/settings';
@import 'core/tools/tools';

/**
 * Theme
 */
@include themify('breadcrumb') using ($component) {
  section.breadcrumb-component {
    background-color: map-get($component, 'background');
    .nav-breadcrumb {
      a, .one-element {
        color: map-get($component, 'text-active');
      }
      span {
        color: map-get($component, 'text');
      }
    }
  }
}

section.breadcrumb-component {
  &.breadcrumb--sticky {
    display: block;
    position: sticky;
    top: 0px;
    z-index: map-get($z-layers, 'small');
    margin: 0 calc(map-get($spacing, 400)*-1);
    padding: 0 map-get($spacing, 400);
  }

  .breadcrumb__margin-top {
    width: 100%;
    height: $header-height;
  }
  
  /**
   * General styles
   */
  .nav-breadcrumb {
    font-family: 'Nunito Sans', sans-serif;
    font-size: map-get($font-size, 300);
    font-weight: 600;
    width: fit-content;
    padding-top: map-get($spacing, 500);
    padding-bottom: map-get($spacing, 600);
  
    display: flex;
    align-items: baseline;
    gap: map-get($spacing, 75);
  
    a, h1, span {
      font-size: inherit;
      font-weight: inherit;
      margin-bottom: 0;
    }
  }
}
