@use 'core/settings/palette' as palette;
@use '@angular/material' as mat;

/*
 * Define a dark theme
 */
$dark-primary: mat.define-palette(palette.$primary, 400);
$dark-accent: mat.define-palette(palette.$primary, 400);
$dark-warn: mat.define-palette(palette.$warn, 600);

$mat-dark-components: (
  foreground: (
    base: $text1,
    divider: rgba(255, 255, 255, 0.12),
    dividers: rgba(255, 255, 255, 0.12),
    disabled: rgba(255, 255, 255, 0.5),
    disabled-button: map-get($gray-palette, 0) ,
    disabled-text: rgba(255, 255, 255, 0.5),
    elevation: black,
    hint-text: rgba(255, 255, 255, 0.5),
    secondary-text: rgba(255, 255, 255, 0.7),
    icon: $text1,
    icons: $text1,
    text: $text1,
    slider-min: $text1,
    slider-off: rgba(255, 255, 255, 0.3),
    slider-off-active: rgba(255, 255, 255, 0.3),
  ),
  background: (
    status-bar: black,
    app-bar: #212121,
    background: #303030,
    hover: rgba(255, 255, 255, 0.04),
    card: $background2,
    dialog: $background2,
    disabled-button: map-get($gray-palette, 1400),
    raised-button: $background2,
    focused-button: rgba(255, 255, 255, 0.12),
    selected-button: #212121,
    selected-disabled-button: #424242,
    disabled-button-toggle: black,
    unselected-chip: #616161,
    disabled-list-option: rgba(255, 255, 255, 0.12),
    tooltip: #616161,
  ),
);

$material-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    ),
  )
);

// Add to main properties
$material-dark-theme: map-merge($material-dark-theme, $mat-dark-components);

// Add to color property
$color: map-get($material-dark-theme, color);
$color: map-merge($color, $mat-dark-components);
$material-dark-theme: map-merge(
  $material-dark-theme,
  (
    color: $color,
  )
);
